body {
    margin: 0;
    color: darkblue;
}

code[class*="language-"],
pre[class*="language-"] {
    margin: 0;
    font-size: 13px;
}

.codejar-linenumbers {
    mix-blend-mode: normal !important;
    text-shadow: none !important;
}
